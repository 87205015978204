



















































































































































































































































































































































































































































































































































































































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
// import debounce from 'lodash.debounce';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import { Debounce } from 'vue-debounce-decorator';
import debounce from 'lodash.debounce';
import { SwipeList, SwipeOut } from 'vue-swipe-actions';
import isOnline from 'is-online';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import { getComponent } from '@/utils/helpers';
import { TASQS_LISTS, SHOW_ALERT } from '@/lib/constants';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import accountModule from '@/store/modules/accountModule';
import workflowModule from '@/store/modules/workflowModule';
import scheduleModule from '@/store/modules/scheduleModule';
// import DataLoading from '@/lib/mixins/dataLoading';

@Component({
  components: {
    TasqItem: () => getComponent('tasqs/TasqItem'),
    PadItem: () => getComponent('tasqs/PadItem'),
    SpinnerLoader: () => getComponent('loaders/SpinnerLoader'),
    SwipeOut,
    Multiselect,
    SwipeList,
  },
})
export default class TasqsVerticalList extends mixins() {
  @Prop({ type: Boolean, default: false }) isMobileView!: boolean;

  filterOpen = false;

  dataLoading = false

  isOnlineApp = navigator.onLine;

  completedVisible = false;

  selectedUsernames: any = []

  selectedRoutes: any = [];

  removeJobType(option) {
    const index = this.typesOptions.indexOf(option);
    if (index !== -1) {
      this.typesOptions.splice(index, 1);
    }
  }

  selectedTeams: any = []

  selectedAreas: any = []

  maxNumCycleMax = 7;

   maxNumDefermentMax = 7;

  cycleTimeGreaterThanFilter: any = null;

  cycleTimeLessThanFilter: any = null;

  unitsGreaterThanFilter: any = null;

  unitsLessThanFilter: any = null;

  filterVal = '';

  activeList = 'ALL';

  showSearch = false;

  isFistLoading = false;

  registerScrollEvent = false;

  registerChangeEvent = false;

  showInfiniteScrollLoading = false;

  cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];

  defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];

  typesOptions = [] as any;

  get isEditing() {
	  return tasqsListModule.isEditing;
  }

  setCycleTimeMaxValue(search) {
    if (search) {
      this.maxNumCycleMax = 1;
      this.cycleTimeValues = [search];
    } else {
      this.maxNumCycleMax = 7;
      this.cycleTimeValues = ['10', '20', '30', '40', '50', '60', '70', '80', '90'];
    }
  }

  setDefermentMaxValue(search) {
    if (search) {
      this.maxNumDefermentMax = 1;
      this.defermentUnitValues = [search];
    } else {
      this.maxNumDefermentMax = 7;
      this.defermentUnitValues = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
    }
  }

  get isLoadingTasqs() {
    this.sleep(500);
    return tasqsListModule.isLoadingTasqs;
  }

  get isLoadingSearchTasqs() {
    return tasqsListModule.isLoadingSearchTasqs;
  }

  get availableUsers() {
    return accountModule.reassignmentList.filter((u) => u && u.email).map((i) => ({
      // @ts-ignore
      text: i.name || 'Test User',
      value: i.email || '',
    })).sort((a, b) => a.text.localeCompare(b.text));
  }

  get allTypes() {
    return tasqsListModule.allPredictionTypes;
  }

  get tasqListLevel() {
	  return tasqsListModule.tasqListLevel;
  }

  get listOptions() {
    return TASQS_LISTS;
  }

  itemClick(e) {
    tasqsListModule.setShowTasqDetailsMobile(true);
  }

  get moreTotalTasqs() {
    return tasqsListModule.todaysTasqLength;
  }

  get morePaginatedData() {
    return (tasqsListModule.currentTasqListPageNo <= tasqsListModule.tasqTotalPages) || tasqsListModule.tasqStartLimit === 0;
  }

  get tasqs(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get producingWells(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get enabledWells() {
    return assetsModule.enabledWells;
  }

  get enableRoutes() {
	  const routes = scheduleModule.enabledRoutes.map((r) => r.Route);
	  routes.sort((a, b) => {
      if (a < b) { return -1; }
      if (a > b) { return 1; }
      return 0;
    });
    return routes;
  }

  get paginationHeight() {
    return tasqsListModule.infiniteScrollHeight;
  }

  get filteredCompletedTasqs(): TasqJob[] {
    let tasqs = this.completedTasqs;
    if (this.filterVal) {
      tasqs = this.completedTasqs.filter(
        (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
      );
    }
    return tasqs.filter((t) => this.typesOptions.includes(t.overriddenName));
  }

  get filteredProducingWells(): any[] {
	  let totalProducingWells: any[] = [];
	  for (let x = 0; x < this.enabledWells.length; x++) {
      let addToProducingWells = true;
      for (let y = 0; y < this.tasqs.length; y++) {
        if (this.tasqs[y].wellName == this.enabledWells[x].wellName) {
          addToProducingWells = false;
        }
      }
      if (addToProducingWells) {
        totalProducingWells.push(this.enabledWells[x]);
      }
	  }
    totalProducingWells = totalProducingWells.filter(
      (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
          || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase()),
    );
	  return totalProducingWells;
  }

  get filteredTasqs(): TasqJob[] {
    const { tasqs } = this;
    // if (this.filterVal) {
    //   tasqs = this.tasqs.filter(
    //     (i) => i.wellName.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.engineerType.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.username.toLowerCase().includes(this.filterVal.toLowerCase())
    //       || i.reassignedUserName?.toLowerCase()?.includes(this.filterVal.toLowerCase()),
    //   );
    // }

    return tasqs.filter((t) => this.typesOptions.includes(t.overriddenName));
  }

  get completedTasqs() {
    return tasqsListModule.completedTasqList;
  }

  get progressPercentage() {
    return tasqsListModule.progressPercentage;
  }

  addTasq() {
	  this.$emit('add-tasq');
  }

  editTasqs() {
	  this.$emit('edit-tasq');
  }

  async reassignTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reassign-tasq');
  }

  async snoozeTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('snooze-tasq');
  }

  async rejectTasq(tasq) {
    await this.fetchTasq(tasq);
	  this.$emit('reject-tasq');
  }

  nameWithDays(name) {
    return `${name} ${name === '1' ? 'Day' : 'Days'}`;
  }

  nameWithUnits(name) {
    return `${name}`;
  }

  setTasqListLevel(level) {
    this.filterOpen = false;
    if (!this.isLoadingTasqs) {
      tasqsListModule.resetPagination();
      tasqsListModule.setTasqListLevel(level);
      this.sleep(500).then(() => {
        this.changeTasqList();
      });
    }
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  closeFilterDialog() {
    this.filterOpen = false;
  }

  showSearchSelected() {
    this.showSearch = true;

    this.sleep(500).then(() => {
	  // @ts-ignore
	  this.$refs.searchTextFieldRef.focus();
    });
  }

  get areas() {
    return scheduleModule.areas;
  }

  get teams() {
    return workflowModule.teams;
  }

  @Debounce(100)
  async changeTasqList(flag = false) {
    if (!this.registerChangeEvent) {
      this.registerChangeEvent = true;
      this.filterOpen = false;
      const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};
      localStorage.setItem('tasqActiveList', JSON.stringify(list));
      // console.log('bug');
      tasqsListModule.setActiveTasq('');
      tasqsListModule.setIsEditing(false);
      tasqsListModule.setIsBatchResponding(false);
      tasqsListModule.setIsBatchReassign(false);
      // tasqsListModule.setActiveTasq('');
      tasqsListModule.resetChekedTasqs();
      tasqsListModule.setActiveFilterList(list);
      tasqsListModule.setSubstringFilter(this.filterVal);
      tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
      tasqsListModule.setActiveAreaFilter(this.selectedAreas);
      tasqsListModule.setPredictionTypeFilter(this.typesOptions);
      tasqsListModule.resetPagination();
	  tasqsListModule.setPaginationLoading(true);
      this.sleep(5000).then(async () => {
        // if (flag) {
        //   if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'TasqList-Well') {
        //     tasqsListModule.setTasqListLevel('Well');
        //   } else if (workflowModule.user.defaultPage && workflowModule.user.defaultPage === 'TasqList-Pad') {
        //     tasqsListModule.setTasqListLevel('Pad');
        //   } else {
        //     const listLevel = accountModule.user != null && accountModule.user.role != null && (accountModule.user.role == 'Operators' || accountModule.user.role == 'FieldOperator') ? 'Pad' : 'Well';
        //     tasqsListModule.setTasqListLevel(listLevel);
        //   }
        // }
        await tasqsListModule.getTasqsByPagination(list);
      });

      // this.$router.push({ name: 'Tasqs' });
      this.sleep(1000).then(() => {
        this.registerChangeEvent = false;
      });
    }
  }

  async resetFiltering() {
    this.selectUsername([]);
    this.selectedUsernames = [];
    this.typesOptions = [];
    this.selectedRoutes = [];
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    await this.applyFiltering();
  }

  async applyFiltering(flag = false) {
    localStorage.setItem('tasqActiveOptions', JSON.stringify(this.typesOptions));
    this.selectedUsernames = this.selectedUsernames.filter((u) => u && u.value);
    // console.log(this.selectedUsernames);
    this.selectUsername(this.selectedUsernames.map((u) => u.value));
    // tasqsListModule.setCycleTimeDaysFilter(this.cycleTimeDays);
    // tasqsListModule.setDefermentDaysFilter(this.defermentDays);

    tasqsListModule.setJobTypeFilter(this.typesOptions);
    tasqsListModule.setActiveTeamFilter(this.selectedTeams);
    tasqsListModule.setActiveRouteFilter(this.selectedRoutes);
    tasqsListModule.setActiveAreaFilter(this.selectedAreas);
    tasqsListModule.setCycleTimeLessThanFilter(this.cycleTimeLessThanFilter);
    tasqsListModule.setUnitsLessThanFilter(this.unitsLessThanFilter);
    tasqsListModule.setUnitsGreaterThanFilter(this.unitsGreaterThanFilter);
    tasqsListModule.setCycleTimeGreaterThanFilter(this.cycleTimeGreaterThanFilter);
    this.filterOpen = false;
    await this.changeTasqList(flag);
  }

  selectUsername(users) {
    tasqsListModule.setUsernamesBulkList(users);
    localStorage.setItem('TasqUsernamesFilter', JSON.stringify(tasqsListModule.usernamesList));
  }

  @Watch('filterVal', { immediate: true })
  @Debounce(2000)
  updateFilterVal() {
    localStorage.setItem('tasqFilterValue', this.filterVal);
    this.changeTasqList();
  }

  paginationHandler({ target: { scrollTop, clientHeight, scrollHeight } }) {
    if (!this.completedVisible) {
      this.showInfiniteScrollLoading = true;
      if (Math.ceil(scrollTop + clientHeight + 150) >= scrollHeight && !this.registerScrollEvent) {
        this.registerScrollEvent = true;
        tasqsListModule.setInfiniteListScroll(scrollHeight);
        const list = this.listOptions.find((i) => i.val === this.activeList) as {text: string, val: string};
        tasqsListModule.getTasqsByPagination(list);
        this.sleep(5000).then(() => {
          // const infiniteList = document.getElementById('infinite-list')!;
          // infiniteList.scrollTo(0, scrollHeight);
          this.showInfiniteScrollLoading = false;
          this.registerScrollEvent = false;
        });
      }
    }
  }

  async fetchTasq(tasq) {
    await tasqsListModule.setActiveTasqExplicit(tasq);
  }

  destroy() {
    // eslint-disable-next-line no-restricted-globals
    removeEventListener('online', this.onlineMode);
    // eslint-disable-next-line no-restricted-globals
    removeEventListener('offline', this.offlineMode);
  }

  async created() {
     this.registerScrollEvent = true
    await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase() });
    this.selectedTeams = [];
    this.cycleTimeGreaterThanFilter = null;
    this.cycleTimeLessThanFilter = null;
    this.unitsGreaterThanFilter = null;
    this.unitsLessThanFilter = null;
    // this.isOnlineApp = await isOnline();
    tasqsListModule.setIsEditing(false);
    tasqsListModule.setActivePage('Tasq');
    await accountModule.getReassignmentList();
    tasqsListModule.setUsernamesList(null);
    tasqsListModule.setUsernamesList(null);
    // console.log(workflowModule.user)
    this.selectedUsernames = [];
    this.selectedRoutes = [];

    if (workflowModule.user.defaultUserFilter === 'All') {
      // No filters applied
      // this.selectedAreas = workflowModule.user.areas;
    } else if (workflowModule.user.defaultUserFilter === 'My Routes' && workflowModule.user.routes) {
      this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    } else if (workflowModule.user.defaultUserFilter === 'Myself') {
      tasqsListModule.setUsernamesList(accountModule.user.email);
      this.selectedUsernames = [(this.availableUsers.find((u) => u.value === accountModule.user
        .email))];
    } else if (workflowModule.user.role === 'My Area' && workflowModule.user.areas) {
      this.selectedAreas = workflowModule.user.areas;
    } else if ((workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.routes) {
      this.selectedRoutes = JSON.parse(workflowModule.user.routes);
    } else if (!(workflowModule.user.role === 'Operators' || workflowModule.user.role === 'FieldOperator') && workflowModule.user.areas) {
      // console.log('My Areas');
      this.selectedAreas = workflowModule.user.areas;
    }

    await this.applyFiltering(true);
    await scheduleModule.listAreas();
    await workflowModule.listTeams();
    await scheduleModule.getEnabledRoutes();

    if (this.isOnlineApp === false) {
      this.$eventBus.$emit(SHOW_ALERT, 'App is running offline mode!');
    }

    window.addEventListener('online', this.onlineMode);

    window.addEventListener('offline', this.offlineMode);

     this.registerScrollEvent = false;
  }

  // get cycleTimeValues(){
  //   let topValues = ['10', '20', '30', '40', '50', '60', '70','80', '90']
  //   let otherValues = (Array.from({length: 300}, (_, i) => `${i + 1}`)).filter(k => !topValues.includes(k) )
  //   return topValues.concat(otherValues)
  // }

  //  get defermentUnitValues(){
  //   let topValues = ['100', '200', '300', '400', '500', '600', '700','800', '900']
  //   let otherValues = (Array.from({length: 300}, (_, i) => `${i + 1}`)).filter(k => !topValues.includes(k) )
  //   return topValues.concat(otherValues)
  // }

  async onlineMode() {
    this.$eventBus.$emit(SHOW_ALERT, 'App is running in online mode!');
    await this.applyFiltering();
  }

  async offlineMode() {
    this.$eventBus.$emit(SHOW_ALERT, 'App is running offline mode!');
    await this.applyFiltering();
  }

  @Watch('isLoadingTasqs')
  @Debounce(500)
  async updateTasqsValue(value) {
    // @ts-ignore
    const scrollDivId = localStorage.getItem('ActiveTasqScroll');
    if (value === false) {
      if (tasqsListModule.kanbanSelectedTasq) {
        tasqsListModule.setKanbanSelectedTasq('');
      }
    }
    this.sleep(2000).then(() => {
      if (scrollDivId && scrollDivId.length) {
        const activeTasqDiv = document.getElementById(scrollDivId)!;
        if (activeTasqDiv) {
          activeTasqDiv.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'nearest' });
          localStorage.setItem('ActiveTasqScroll', '');
        }
      }
    });
  }
}
